import * as React from 'react'
import { useForm, FormProvider, useFieldArray } from 'react-hook-form'
import { LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'
import { CustomForm } from 'components/atoms/FormComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import {
  createEducationAnnualPlan,
  fetchEducationAnnualPlans,
  updateEducationAnnualPlan,
} from 'components/apis/educationAnnualPlans'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { useErrorHandler } from 'react-error-boundary'
import { useQuery } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  getLocalStorageDisplayLimit,
  getLocalStorageSearchParams,
  getNextResourceId,
} from 'commons/table'
import FormSubmitSelectPageModal from 'components/atoms/FormSubmitSelectPageModal'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { slackNotification } from 'commons/slackNotification'
import { UserContext } from 'providers/UserProvider'
import { getEducationAnnualPlan } from 'components/apis/educationAnnualPlans'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useParams } from 'react-router-dom'
import { Subject, EducationPlanHeader } from './components'

interface Props {
  type: 'create' | 'update' | 'duplicate'
}

export default function AdminEducationAnnualPlansForm(props: Props) {
  const { id } = useParams()
  const user = React.useContext(UserContext)
  const [customEducations, setcustomEducations] = React.useState<Education[]>(
    []
  )

  const { data: educationAnnualPlan, isLoading: isAnnualPlanLoading } =
    useQuery(['educationAnnualPlan'], () =>
      getEducationAnnualPlan(parseInt(id)).then((res) => res.data)
    )

  if (educationAnnualPlan && props.type === 'duplicate') {
    educationAnnualPlan.annualPlanTitle = `${educationAnnualPlan.annualPlanTitle}のコピー`
    educationAnnualPlan.year = educationAnnualPlan.year + 1
    educationAnnualPlan.subjects.forEach((subject) => {
      const date = new Date(subject.scheduleDate)
      const scheduleDate = String(subject.scheduleDate)
      subject.scheduleDate = date.getFullYear() + 1 + scheduleDate.substring(4)
    })
  }

  const handleError = useErrorHandler()
  const navigate = useNavigate()
  const [isModalShow, setModalShow] = React.useState(false)
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const doMethod =
    props.type === 'create' || props.type === 'duplicate'
      ? createEducationAnnualPlan
      : updateEducationAnnualPlan

  const mutation = useMutation({
    mutationFn: doMethod,
    onError: (e: AxiosError) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors)
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const submit = async (callback: () => void) => {
    setModalShow(false)
    const hasNotError = await methods.trigger(undefined, { shouldFocus: true })
    if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  /**
   *
   * @param id
   * - 「次のレコード」を取得して遷移するメソッド
   * - TODO: 全件取得してフロント側で「次のレコード」を見つける実装なのでdisplayLimitを越えた場合に機能しない。バックエンド側で「次のレコード」を見つけて返すようにしたい。
   */
  const navigateToNextResource = async (id: number) => {
    // localStorageから検索条件を取得
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN
    )
    const countPerPage = getLocalStorageDisplayLimit()
    const educationAnnualPlansPerDisplayLimit = await fetchEducationAnnualPlans(
      searchParams,
      {
        displayLimit: 1000,
      }
    ).then((res) => {
      // 検索条件を適用してfetch, 結果をcountPerPage毎に分割
      const arr = []
      while (res.data.educationAnnualPlans.length > 1) {
        const per = res.data.educationAnnualPlans.splice(0, countPerPage)
        arr.push(per)
      }
      return arr
    })
    // 「次のデータ」のidを取得
    const nextId = getNextResourceId<EducationAnnualPlan>(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN,
      id,
      educationAnnualPlansPerDisplayLimit
    )
    if (nextId === null) {
      navigate(`/admin/educations/annual_plans/`)
      return
    }
    navigate(`/admin/educations/annual_plans/${nextId}/`)
  }

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { subjects: educationAnnualPlan?.subjects },
  })

  React.useEffect(() => {
    if (educationAnnualPlan) {
      methods.reset({
        ...educationAnnualPlan,
      })
    }
    setcustomEducations(educationAnnualPlan?.customEducations ?? [])
  }, [educationAnnualPlan, methods.reset])

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'subjects',
  })

  const addSubject = () => {
    append({
      education: {
        id: 0,
        title: '',
        purpose: '',
        summary: '',
        chapter: 0,
        documentPath: '',
        videoUrl: '',
        questionCount: 0,
        videoType: '',
        videoThumbnailUrl: '',
        category: 'custom',
        files: [],
      },
      scheduleDate: '',
      content: '',
      organizer: '',
      location: '',
      videos: [],
    })
  }

  const submitCallback = async (to: 'show' | 'new' | 'next' | 'index') => {
    setIsProcessingModalShow(true)
    const formData = methods.getValues()
    const data: EducationAnnualPlan = {
      id: educationAnnualPlan?.id,
      annualPlanTitle: formData['annualPlanTitle'],
      year: formData['year'],
      subjects: fields,
      customEducations: formData['customEducations'],
      companyId: formData['companyId'],
      officeId: formData['officeId'],
      ...formData,
      ...fields,
    }
    mutation.mutate(data, {
      onSuccess: (res) => {
        scrollTo({ top: 0 })
        if (to === 'new') {
          if (props.type === 'create') {
            return navigate(0)
          }
          navigate('/admin/educations/annual_plans/new/')
        } else if (to === 'show') {
          navigate(`/admin/educations/annual_plans/${res.data.id}/`)
        } else if (to === 'next') {
          navigateToNextResource(res.data.id)
        } else if (to === 'index') {
          navigate(`/admin/educations/annual_plans?focus_id=${res.data.id}`)
        }
      },
      onSettled: () => {
        setIsProcessingModalShow(false)
      },
    })
  }

  if (isAnnualPlanLoading) {
    return <CircularProgressBox />
  }

  return (
    <>
      <FormProvider {...methods}>
        <CustomForm>
          {props.type === 'duplicate' && (
            <p>年度と各項目の受講予定日が1年加算されています</p>
          )}
          <EducationPlanHeader educationAnnualPlan={educationAnnualPlan} />
          {fields.map((field, index) => (
            <Subject
              key={field.id}
              index={index}
              data={field}
              customEducations={customEducations}
              onDelete={() => remove(index)}
            />
          ))}
          <button type="button" onClick={addSubject}>
            項目を追加
          </button>
        </CustomForm>
      </FormProvider>
      <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
        <ApplyButton
          pattern="block"
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <FormSubmitSelectPageModal
        isOpen={isModalShow}
        resourceName="年間計画"
        handlers={{
          cancel: () => setModalShow(false),
          toShow: () => submit(() => submitCallback('show')),
          toIndex: () => submit(() => submitCallback('index')),
        }}
      ></FormSubmitSelectPageModal>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}
