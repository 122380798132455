import * as React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import EducationsIndex from 'components/pages/educations/index'
import EducationCommentsNew from 'components/pages/education_comments/new'
import EducationTestsIndex from 'components/pages/education_tests/index'
import EducationTestsShow from 'components/pages/education_tests/show'
import EducationAttendancesIndex from 'components/pages/education_attendances/index'
import AccidentsIndex from 'components/pages/accidents/index'
import AccidentsNew from 'components/pages/accidents/new'
import AccidentsShow from 'components/pages/accidents/show'
import AccidentsEdit from 'components/pages/accidents/edit'
import NotificationIndex from 'components/pages/notifications/index'
import ArticlesIndex from 'components/pages/articles/index'
import ArticlesShow from 'components/pages/articles/show'
import ArticleMaintenancesIndex from 'components/pages/articles/maintenances/index'
import ArticleMaintenancesNew from 'components/pages/articles/maintenances/new'
import ArticleMaintenancesShow from 'components/pages/articles/maintenances/show'
import ArticleMaintenancesEdit from 'components/pages/articles/maintenances/edit'
import UserArticlesIndex from 'components/pages/user_articles/index'
import AdminRoute from 'router/AdminRoute'
import XmileAdminRoute from 'router/XmileAdminRoute'
import AdminAccidentsEdit from 'components/pages/admin/accidents/edit'
import AdminAccidentsIndex from 'components/pages/admin/accidents/index'
import AdminAccidentsNew from 'components/pages/admin/accidents/new'
import AdminAccidentsShow from 'components/pages/admin/accidents/show'
import AdminAlcoholChecksIndex from 'components/pages/admin/alcohol_checks'
import AdminAlcoholChecksNew from 'components/pages/admin/alcohol_checks/new'
import AdminAlcoholChecksShow from 'components/pages/admin/alcohol_checks/show'
import AdminCompaniesEdit from 'components/pages/admin/companies/edit'
import AdminCompaniesIndex from 'components/pages/admin/companies/index'
import AdminCompaniesNew from 'components/pages/admin/companies/new'
import AdminCompaniesShow from 'components/pages/admin/companies/show'
import AdminDriversEdit from 'components/pages/admin/drivers/edit'
import AdminDriversIndex from 'components/pages/admin/drivers/index'
import AdminDriversNew from 'components/pages/admin/drivers/new'
import AdminDriversShow from 'components/pages/admin/drivers/show'
import AdminDriversEducationTestsHistory from 'components/pages/admin/drivers/education_test_history'
import AdminEducationAnnualPlansEdit from 'components/pages/admin/education_annual_plans/edit'
import AdminEducationAnnualPlansIndex from 'components/pages/admin/education_annual_plans/index'
import AdminEducationAnnualPlansNew from 'components/pages/admin/education_annual_plans/new'
import AdminEducationAnnualPlansShow from 'components/pages/admin/education_annual_plans/show'
import AdminEducationAttendancesChapterIndex from 'components/pages/admin/education_attendances/chapter_index'
import AdminEducationAttendancesEdit from 'components/pages/admin/education_attendances/edit'
import AdminEducationAttendancesIndex from 'components/pages/admin/education_attendances'
import AdminEducationAttendancesNew from 'components/pages/admin/education_attendances/new'
import AdminEducationAttendancesShow from 'components/pages/admin/education_attendances/show'
import AdminEducationExportCsvIndex from 'components/pages/admin/education_export_csv'
import AdminEducationsIndex from 'components/pages/admin/educations/index'
import AdminEducationsNew from 'components/pages/admin/educations/new'
import AdminEducationsEdit from 'components/pages/admin/educations/edit'
import AdminEducationTestsIndex from 'components/pages/admin/education_tests/index'
import AdminEducationTestsShow from 'components/pages/admin/education_tests/show'
import AdminEducationCommentsIndex from 'components/pages/admin/education_comments/index'
import AdminEducationCommentsChapterIndex from 'components/pages/admin/education_comments/chapter_index'
import AdminEducationCommentsNew from 'components/pages/admin/education_comments/new'
import AdminEducationSettings from 'components/pages/admin/education_settings/edit'
import AdminNotificationIndex from 'components/pages/admin/notifications/index'
import AdminNotificationShow from 'components/pages/admin/notifications/show'
import AdminVehiclesEdit from 'components/pages/admin/vehicles/edit'
import AdminVehiclesIndex from 'components/pages/admin/vehicles/index'
import AdminVehiclesNew from 'components/pages/admin/vehicles/new'
import AdminVehiclesShow from 'components/pages/admin/vehicles/show'
import Index from 'components/pages/index'
import Error from 'components/pages/errors/Error'
import EducationVideosIndex from 'components/pages/education_videos'
import AdminEducationVideoLogsIndex from 'components/pages/admin/education_video_logs/index'
import AdminEducationVideoLogsChapterIndex from 'components/pages/admin/education_video_logs/chapter_index'
import ExecutivesIndex from 'components/pages/executives/index'
import ExecutivesFundContactsIndex from 'components/pages/executives/fund_contacts/index'
import ExecutivesFundContactsNew from 'components/pages/executives/fund_contacts/new'
import ExecutivesFundContactsComplete from 'components/pages/executives/fund_contacts/complete'
import AdminCsvSettingsIndex from 'components/pages/admin/csv_settings'
import AdminCsvSettingsDriverImportEdit from 'components/pages/admin/csv_settings/driver_import/edit'
import AdminCsvSettingsVehicleImportEdit from 'components/pages/admin/csv_settings/vehicle_import/edit'
import AdminCsvSettingsCautionsDriverImportIndex from 'components/pages/admin/csv_settings/cautions/driver_import/index'
import AdminCsvSettingsCautionsVehicleImportIndex from 'components/pages/admin/csv_settings/cautions/vehicle_import/index'

import { UserContext } from 'providers/UserProvider'
import ExecutiveAdminRoute from './ExecutiveAdminRoute'
import ExecutiveSystemAdminRoute from './ExecutiveSystemAdminRoute'
import AdminEducationAnnualPlansDuplicate from 'components/pages/admin/education_annual_plans/duplicate'

function Router() {
  const user: User = React.useContext(UserContext)

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Index />} />
        <Route path="errors" element={<Error />} />
        <Route path="notifications">
          <Route index element={<NotificationIndex />} />
        </Route>
        <Route path="articles">
          <Route index element={<ArticlesIndex />} />
          <Route path=":id" element={<ArticlesShow />} />
        </Route>
        <Route path="educations">
          <Route index element={<EducationsIndex />} />
          <Route path="videos">
            <Route index element={<EducationVideosIndex />} />
          </Route>
          <Route path="tests">
            <Route index element={<EducationTestsIndex />} />
            <Route path=":id" element={<EducationTestsShow />} />
          </Route>
          <Route path="attendances">
            <Route index element={<EducationAttendancesIndex />} />
          </Route>
          <Route path=":id/comments">
            <Route path="new" element={<EducationCommentsNew />} />
          </Route>
        </Route>
        <Route path="accidents">
          <Route index element={<AccidentsIndex />} />
          <Route path="new" element={<AccidentsNew />} />
          <Route path=":id" element={<AccidentsShow />} />
          <Route path=":id/edit" element={<AccidentsEdit />} />
        </Route>
        {/* admin */}
        <Route path="admin">
          <Route path="articles">
            <Route
              index
              element={<AdminRoute component={<ArticlesIndex />} />}
            />
            <Route
              path=":id"
              element={<AdminRoute component={<ArticlesShow />} />}
            />
            <Route path="maintenances">
              <Route
                index
                element={
                  <AdminRoute component={<ArticleMaintenancesIndex />} />
                }
              />
              <Route
                path="new"
                element={<AdminRoute component={<ArticleMaintenancesNew />} />}
              />
              <Route
                path=":id"
                element={<AdminRoute component={<ArticleMaintenancesShow />} />}
              />
              <Route
                path=":id/edit"
                element={<AdminRoute component={<ArticleMaintenancesEdit />} />}
              />
              <Route
                path=":id/user_articles"
                element={<AdminRoute component={<UserArticlesIndex />} />}
              />
            </Route>
          </Route>
          <Route path="educations">
            <Route
              index
              element={<AdminRoute component={<AdminEducationsIndex />} />}
            />
            <Route
              path="new"
              element={<AdminRoute component={<AdminEducationsNew />} />}
            />
            <Route
              path=":id/edit"
              element={<AdminRoute component={<AdminEducationsEdit />} />}
            />
            <Route path="videos">
              <Route index element={<EducationVideosIndex />} />
            </Route>
            <Route path="video_logs">
              <Route
                index
                element={
                  <AdminRoute component={<AdminEducationVideoLogsIndex />} />
                }
              />
              <Route path=":id">
                <Route
                  index
                  element={
                    <AdminRoute
                      component={<AdminEducationVideoLogsChapterIndex />}
                    />
                  }
                />
              </Route>
            </Route>
            <Route path="tests">
              (
              <>
                <Route
                  index
                  element={
                    <AdminRoute component={<AdminEducationTestsIndex />} />
                  }
                />
                <Route
                  path=":id"
                  element={
                    <AdminRoute component={<AdminEducationTestsShow />} />
                  }
                />
              </>
              )
            </Route>
            <Route path="attendances">
              (
              <Route
                index
                element={
                  <AdminRoute component={<AdminEducationAttendancesIndex />} />
                }
              />
              )
              <Route path=":id">
                (
                <Route
                  index
                  element={
                    <AdminRoute
                      component={<AdminEducationAttendancesChapterIndex />}
                    />
                  }
                />
                )
                <Route
                  path="new"
                  element={
                    <AdminRoute component={<AdminEducationAttendancesNew />} />
                  }
                />
                <Route
                  path=":user_education_id"
                  element={
                    <AdminRoute component={<AdminEducationAttendancesShow />} />
                  }
                />
                <Route
                  path=":user_education_id/edit"
                  element={
                    <AdminRoute component={<AdminEducationAttendancesEdit />} />
                  }
                />
              </Route>
            </Route>
            <Route path="export_csv">
              <Route
                index
                element={
                  <AdminRoute component={<AdminEducationExportCsvIndex />} />
                }
              />
            </Route>
            <Route path="annual_plans">
              <Route
                index
                element={
                  <AdminRoute component={<AdminEducationAnnualPlansIndex />} />
                }
              />
              <Route
                path="new"
                element={
                  <AdminRoute component={<AdminEducationAnnualPlansNew />} />
                }
              />
              <Route
                path=":id"
                element={
                  <AdminRoute component={<AdminEducationAnnualPlansShow />} />
                }
              />
              <Route
                path=":id/edit"
                element={
                  <AdminRoute component={<AdminEducationAnnualPlansEdit />} />
                }
              />
              <Route
                path=":id/duplicate"
                element={
                  <AdminRoute
                    component={<AdminEducationAnnualPlansDuplicate />}
                  />
                }
              />
            </Route>
            <Route path="settings">
              <Route
                index
                element={<AdminRoute component={<AdminEducationSettings />} />}
              />
            </Route>
            <Route path="comments">
              <Route
                index
                element={
                  <AdminRoute component={<AdminEducationCommentsIndex />} />
                }
              />
            </Route>
            <Route path=":id/comments">
              <Route
                index
                element={
                  <AdminRoute
                    component={<AdminEducationCommentsChapterIndex />}
                  />
                }
              />
              <Route
                path="new"
                element={
                  <AdminRoute component={<AdminEducationCommentsNew />} />
                }
              />
            </Route>
          </Route>
          {user.admin && (
            <Route path="alcohol_checks">
              <Route
                index
                element={<AdminRoute component={<AdminAlcoholChecksIndex />} />}
              />
              <Route
                path="new"
                element={<AdminRoute component={<AdminAlcoholChecksNew />} />}
              />
              <Route
                path=":id"
                element={
                  <AdminRoute
                    component={<AdminAlcoholChecksShow />}
                    options={{ currentUser: true }}
                  />
                }
              />
            </Route>
          )}
          <Route path="accidents">
            (
            <>
              <Route
                index
                element={<AdminRoute component={<AdminAccidentsIndex />} />}
              />
              <Route
                path="new"
                element={<AdminRoute component={<AdminAccidentsNew />} />}
              />
              <Route
                path=":id"
                element={<AdminRoute component={<AdminAccidentsShow />} />}
              />
              <Route
                path=":id/edit"
                element={<AdminRoute component={<AdminAccidentsEdit />} />}
              />
            </>
            )
          </Route>
          <Route path="companies">
            <Route
              index
              element={<AdminRoute component={<AdminCompaniesIndex />} />}
            />
            <Route
              path="new"
              element={<XmileAdminRoute component={<AdminCompaniesNew />} />}
            />
            <Route
              path=":id"
              element={<AdminRoute component={<AdminCompaniesShow />} />}
            />
            <Route
              path=":id/edit"
              element={<AdminRoute component={<AdminCompaniesEdit />} />}
            />
          </Route>
          <Route path="drivers">
            <Route
              index
              element={<AdminRoute component={<AdminDriversIndex />} />}
            />
            <Route
              path="new"
              element={<AdminRoute component={<AdminDriversNew />} />}
            />
            <Route
              path=":id"
              element={
                <AdminRoute
                  component={<AdminDriversShow />}
                  options={{ currentUser: true }}
                />
              }
            />
            <Route
              path=":id/tests/:educationId"
              element={
                <AdminRoute
                  component={<AdminDriversEducationTestsHistory />}
                  options={{ currentUser: true }}
                />
              }
            />
            <Route
              path=":id/edit"
              element={
                <AdminRoute
                  component={<AdminDriversEdit />}
                  options={{ currentUser: true }}
                />
              }
            />
          </Route>
          <Route path="vehicles">
            <Route
              index
              element={<AdminRoute component={<AdminVehiclesIndex />} />}
            />
            <Route
              path="new"
              element={<AdminRoute component={<AdminVehiclesNew />} />}
            />
            <Route
              path=":id"
              element={<AdminRoute component={<AdminVehiclesShow />} />}
            />
            <Route
              path=":id/edit"
              element={<AdminRoute component={<AdminVehiclesEdit />} />}
            />
          </Route>
          <Route path="notifications">
            <Route index element={<AdminNotificationIndex />} />
            <Route
              path=":id"
              element={<AdminRoute component={<AdminNotificationShow />} />}
            />
          </Route>
          <Route path="csv_settings">
            <Route
              index
              element={<AdminRoute component={<AdminCsvSettingsIndex />} />}
            />
            <Route
              path="driver_import"
              element={
                <AdminRoute component={<AdminCsvSettingsDriverImportEdit />} />
              }
            />
            <Route
              path="vehicle_import"
              element={
                <AdminRoute component={<AdminCsvSettingsVehicleImportEdit />} />
              }
            />
            <Route path="cautions">
              <Route
                path="driver_import"
                element={
                  <AdminRoute
                    component={<AdminCsvSettingsCautionsDriverImportIndex />}
                  />
                }
              />
              <Route
                path="vehicle_import"
                element={
                  <AdminRoute
                    component={<AdminCsvSettingsCautionsVehicleImportIndex />}
                  />
                }
              />
            </Route>
          </Route>
        </Route>
        <Route path="executives">
          <Route
            index
            element={<ExecutiveAdminRoute component={<ExecutivesIndex />} />}
          />
          <Route path="fund_contacts">
            <Route
              index
              element={
                <XmileAdminRoute component={<ExecutivesFundContactsIndex />} />
              }
            />
            <Route
              path="new"
              element={
                <ExecutiveSystemAdminRoute
                  component={<ExecutivesFundContactsNew />}
                />
              }
            />
            <Route
              path="complete"
              element={
                <ExecutiveSystemAdminRoute
                  component={<ExecutivesFundContactsComplete />}
                />
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
