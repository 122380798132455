export const ENUMS = {
  ACCIDENTS: {
    DAMAGED_OBJECT: {
      PERSON: {
        value: 'person',
        label: '対人',
      },
      OBJECTIVE: {
        value: 'objective',
        label: '対物',
      },
    },
    RESPONSIBILITY: {
      OURS: {
        value: 'ours',
        label: '加害',
      },
      OTHERS: {
        value: 'others',
        label: '被害',
      },
    },
    STATUS: {
      WORKING: {
        value: 'working',
        label: '対応中',
      },
      WAITING: {
        value: 'waiting',
        label: '対応待ち',
      },
      DONE: {
        value: 'done',
        label: '完了',
      },
    },
    ACCIDENT_TYPE: {
      TRAFFIC: {
        value: 'traffic',
        label: '交通事故',
      },
      WORK: {
        value: 'work',
        label: '作業事故',
      },
    },
    PRODUCT_ACCIDENT: {
      YES: {
        value: 1,
        label: 'あり',
      },
      NO: {
        value: 0,
        label: 'なし',
      },
    },
    QUICK_REPORT: {
      YES: {
        value: 1,
        label: '対象',
      },
      NO: {
        value: 0,
        label: '対象外',
      },
    },
  },
  SALES: {
    SUBJECT: {
      SALES: {
        value: 'sales',
        label: '売上',
      },
    },
    DISPLAY_TYPE: {
      TOTAL: {
        value: 'total',
        label: '合計',
      },
      FILTER: {
        value: 'filter',
        label: '項目別',
      },
    },
    EXPORT_TYPE: {
      DAILY: {
        value: 'daily',
        label: '日次',
      },
      MONTHLY: {
        value: 'monthly',
        label: '月次',
      },
    },
    EXPORT_RANGE: {
      INHOUSE: {
        value: 'inhouse',
        label: '自社配送',
      },
      DELEGATE: {
        value: 'delegate',
        label: '委託配送',
      },
      ALL: {
        value: 'all',
        label: '全て',
      },
    },
    FILTER_TYPE: {
      VEHICLE: {
        value: 'vehicle_id',
        label: '車両別',
      },
      DRIVER: {
        value: 'driver_id',
        label: 'ドライバー別',
      },
      USER: {
        value: 'inhouse_charge_person_id',
        label: '案件担当別',
      },
      OWNER: {
        value: 'owner_id',
        label: '荷主別',
      },
      OFFICE: {
        value: 'office_id',
        label: '事業所別',
      },
    },
  },
  DELIVERY_REQUESTS: {
    DEADLINE_TYPE: {
      TILL: {
        value: 'till',
        label: 'までに',
      },
      AFTER: {
        value: 'after',
        label: '以降',
      },
      JUST: {
        value: 'just',
        label: 'ちょうど',
      },
      EARLIEST: {
        value: 'earliest',
        label: '最短',
      },
      TERM: {
        value: 'term',
        label: '時間帯指定',
      },
    },
    VEHICLE_OPTION: {
      VEHICLE_COUNT: {
        value: 'vehicle_count',
        label: '台数',
      },
      TOTAL_WEIGHT: {
        value: 'total_weight',
        label: '重量',
      },
    },
    DELEGATE_INSURANCE: {
      TRUE: {
        value: 1,
        label: 'あり',
      },
      FALSE: {
        value: 0,
        label: 'なし',
      },
    },
    DELIVERY_CONTRACT_TYPE: {
      INHOUSE: {
        value: 'inhouse',
        label: '自社配送',
      },
      DELEGATION: {
        value: 'delegation',
        label: '委託配送',
      },
    },
    IS_DELEGATION_DELIVERY: {
      FALSE: {
        value: 0,
        label: '自社配送',
      },
      TRUE: {
        value: 1,
        label: '委託配送',
      },
    },
    CONTRACT_TYPE: {
      TIME_CHARGING: {
        value: 'time_charging',
        label: '時間制貸切',
      },
      DISTANCE_CHARGING: {
        value: 'distance_charging',
        label: '距離制貸切',
      },
    },
    TASK_DETAIL: {
      TASK_DETAIL_NULL: {
        value: null,
        label: '未選択',
      },
      FORKLIFT_USE_OWNER: {
        value: 'forklift_use_owner',
        label: 'フォークリフト（自主荷役あり）',
      },
      FORKLIFT_USE_DRIVER: {
        value: 'forklift_use_driver',
        label: 'フォークリフト（自主荷役なし）',
      },
      HANDLIFT: {
        value: 'handlift',
        label: 'ハンドリフト',
      },
      UNLOADING: {
        value: 'unloading',
        label: 'バラ',
      },
      CRANE: {
        value: 'crane',
        label: 'クレーン',
      },
      OTHER: {
        value: 'other',
        label: 'その他',
      },
    },
    CARGO_TYPE: {
      FOOD_AND_DRINK: {
        value: 'food_and_drink',
        label: '食品・飲料',
      },
      LIFE_GOODS: {
        value: 'life_goods',
        label: '生活雑貨',
      },
      FURNITURE: {
        value: 'furniture',
        label: '什器',
      },
      BUILDING_MATERIAL: {
        value: 'building_material',
        label: '建材',
      },
      OFFICE_GOODS: {
        value: 'office_goods',
        label: 'オフィス用品',
      },
      PRINTED: {
        value: 'printed',
        label: '紙・印刷物',
      },
      MATERIALS: {
        value: 'materials',
        label: '資材・原料',
      },
      HOUSING_EQUIPMENT: {
        value: 'housing_equipment',
        label: '住設機器',
      },
      PRECISION_MACHINE: {
        value: 'precision_machine',
        label: '精密機器',
      },
      CLOTHING: {
        value: 'clothing',
        label: '衣類品',
      },
      ENERGY: {
        value: 'energy',
        label: 'エネルギー類',
      },
      WASTE: {
        value: 'waste',
        label: '廃棄物',
      },
      FARM_PRODUCTS_AND_FERTILIZER: {
        value: 'farm_products_and_fertilizer',
        label: '農産物・肥料',
      },
      TRANSPORTAION_MACHINE: {
        value: 'transportation_machinery',
        label: '輸送用機械・部品・容器',
      },
      CHEMICALS: {
        value: 'chemicals',
        label: '化学薬品',
      },
      STACKED: {
        value: 'stacked',
        label: '取り合わせ品',
      },
      UNKNOWN: {
        value: 'unknown',
        label: '荷物不明',
      },
    },
    PACKING_STYLE: {
      PALLET: {
        value: 'pallet',
        label: 'パレット',
      },
      CASE: {
        value: 'case',
        label: 'ケース',
      },
      PAGE_BAND: {
        value: 'paper_band',
        label: '紙帯',
      },
      FREIGHT_CONVEYOR: {
        value: 'freight_conveyor',
        label: 'フレコン',
      },
      NONE_PACKING: {
        value: 'none_package',
        label: '荷姿そのまま',
      },
      ROLL_BOX_PALLET: {
        value: 'roll_box_pallet',
        label: 'カゴ台車',
      },
      CONTAINER: {
        value: 'container',
        label: 'コンテナ',
      },
      DRUM: {
        value: 'drum',
        label: 'ドラム缶',
      },
      PLASTIC_CONTAINER: {
        value: 'plastic_container',
        label: '番重',
      },
      OTHER: {
        value: 'other',
        label: 'その他',
      },
    },
    SHIPMENT_REQUEST_RESULT: {
      NO_ACTION_REQUIRED: {
        value: 'no_action_required',
        label: '送信不要',
      },
      UNSENT: {
        value: 'unrequested',
        label: '未送信',
      },
      REQUESTING: {
        value: 'requesting',
        label: '送信中',
      },
      SUCCESS: {
        value: 'success',
        label: '送信成功',
      },
      FAILURE: {
        value: 'failure',
        label: '送信失敗',
      },
      UNSENT_WITH_CHANGES: {
        value: 'unrequested_with_changes',
        label: '未送信(変更あり)',
      },
    },
  },
  PROPOSAL_DELIVERY_REQUESTS: {
    VEHICLE_TYPE: {
      DRYWING4T: {
        value: 'dry_wing_4t',
        label: '4tドライウイング',
      },
      FLAT13T: {
        value: 'flat_13t',
        label: '13t平車',
      },
    },
    VEHICLE_TYPE_DETAIL: {
      LOW_FLOOR: {
        value: 'low_floor',
        label: '低床',
      },
      MEDIUM_FLOOR: {
        value: 'medium_floor',
        label: '中低床',
      },
      HIGH_FLOOR: {
        value: 'high_floor',
        label: '高床',
      },
      WIDE: {
        value: 'wide',
        label: 'ワイド',
      },
      SEMI_WIDE: {
        value: 'semi_wide',
        label: 'セミワイド',
      },
      STANDARD: {
        value: 'standard',
        label: '標準',
      },
      SHORT: {
        value: 'short',
        label: 'ショート',
      },
      LONG: {
        value: 'long',
        label: 'ロング',
      },
      NONE_GATE_BODY: {
        value: 'none_gate_body',
        label: 'あおりなし',
      },
      AIR_SUSPENSION: {
        value: 'air_suspension',
        label: 'エアサス付き',
      },
    },
    PROPOSAL_STATUS: {
      PROPOSING: {
        value: 'proposing',
        label: '打診中',
      },
      ACCEPTED: {
        value: 'accepted',
        label: '受諾',
      },
      REJECTED: {
        value: 'rejected',
        label: '拒否',
      },
    },
  },
  VEHICLES: {
    NUMBER_PLATE_TYPE: {
      BUSINESS: {
        value: 'business',
        label: '業務用',
      },
      PRIVATE: {
        value: 'private',
        label: '自家用',
      },
    },
    GREEN_MANAGEMENT_VEHICLE_TYPE: {
      SMALL_OR_ORDINARY_CARGO: {
        value: 'small_or_ordinary_cargo',
        label: '小型・普通貨物自動車',
      },
      SPECIAL_PURPOSE: {
        value: 'special_purpose',
        label: '特種用途自動車(ローリー、冷凍車等)',
      },
      COMPRESSED_NATURAL_GASS: {
        value: 'compressed_natural_gass',
        label: '天然ガス自動車(CNG自動車)',
      },
      ELECTRIC: {
        value: 'electric',
        label: '電気自動車',
      },
      HYBRID_DIESEL: {
        value: 'hybrid_diesel',
        label: 'ハイブリッド自動車(軽油)',
      },
      HYBRID_GASOLINE: {
        value: 'hybrid_gasoline',
        label: 'ハイブリッド自動車(ガソリン)',
      },
      GASOLINE: {
        value: 'gasoline',
        label: 'ガソリン自動車',
      },
      LPG: {
        value: 'lpg',
        label: 'LPG自動車',
      },
    },
    SIZES: {
      NORMAL: {
        value: 'normal',
        label: '標準',
      },
      SHORT: {
        value: 'short',
        label: 'ショート',
      },
      LONG: {
        value: 'long',
        label: 'ロング',
      },
      WIDE: {
        value: 'wide',
        label: 'ワイド',
      },
    },
    OPERATION_STATUS_TYPE: {
      WORKING: {
        value: 'working',
        label: '稼働中',
      },
      RESERVE: {
        value: 'reserve',
        label: '予備',
      },
      MAINTENANCE: {
        value: 'maintenance',
        label: '整備・修理中',
      },
      DISABLED: {
        value: 'disabled',
        label: '廃車・売却済',
      },
      OTHERS: {
        value: 'others',
        label: 'その他',
      },
    },
    LICENSE: {
      STANDARD: {
        value: 'standard',
        label: '普通',
      },
      LIMITED_SEMI_MIDSIZE: {
        value: 'limited_semi_midsize',
        label: '準中型(5t限定)',
      },
      SEMI_MIDSIZE: {
        value: 'semi_midsize',
        label: '準中型',
      },
      LIMITED_MIDSIZE: {
        value: 'limited_midsize',
        label: '中型(8t限定)',
      },
      MIDSIZE: {
        value: 'midsize',
        label: '中型',
      },
      LARGE: {
        value: 'large',
        label: '大型',
      },
      LARGE_SPECIAL: {
        value: 'large_special',
        label: '大型特殊',
      },
      SMALL_SPECIAL: {
        value: 'small_special',
        label: '小型特殊',
      },
      TOWING: {
        value: 'towing',
        label: '牽引',
      },
    },
    LICENSE_CAR_TYPE: {
      LIGHT: {
        value: 'light',
        label: '軽',
      },
      SMALL: {
        value: 'small',
        label: '小型',
      },
      NORMAL: {
        value: 'normal',
        label: '普通',
      },
      SMALL_SPECIAL: {
        value: 'small_special',
        label: '小型特殊',
      },
      LARGE_SPECIAL: {
        value: 'large_special',
        label: '大型特殊',
      },
    },
    USE_CAR_TYPE: {
      PASSENGER: {
        value: 'passenger',
        label: '乗用',
      },
      FREIGHT: {
        value: 'freight',
        label: '貨物',
      },
      SPECIAL: {
        value: 'special',
        label: '特殊',
      },
    },
    CAR_TYPE: {
      BUSINESS: {
        value: 'business',
        label: '業務用',
      },
      PRIVSTE: {
        value: 'private',
        label: '自家用',
      },
    },
    CAB_TYPE: {
      NORMAL: {
        value: 'normal',
        label: '標準',
      },
      WIDE: {
        value: 'wide',
        label: 'ワイド',
      },
      FULL: {
        value: 'full',
        label: 'フル',
      },
      HIGH: {
        value: 'high',
        label: 'ハイ',
      },
      DOUBLE: {
        value: 'double',
        label: 'ダブル',
      },
    },
    SCR_TYPE: {
      YES: {
        value: 1,
        label: 'あり',
      },
      NO: {
        value: 0,
        label: 'なし',
      },
    },
    MITION_TYPE: {
      MT: {
        value: 'mt',
        label: 'MT',
      },
      AT: {
        value: 'at',
        label: 'AT',
      },
      SPECIAL: {
        value: 'special',
        label: '特殊',
      },
      SEMI_AT: {
        value: 'semi-at',
        label: 'セミオートマ',
      },
    },
    BED_TYPE: {
      YES: {
        value: 1,
        label: 'あり',
      },
      NO: {
        value: 0,
        label: 'なし',
      },
    },
    COLD_TYPE: {
      YES: {
        value: 1,
        label: 'あり',
      },
      NO: {
        value: 0,
        label: 'なし',
      },
    },
    SIDE_DOOR_TYPE: {
      YES: {
        value: 1,
        label: 'あり',
      },
      NO: {
        value: 0,
        label: 'なし',
      },
    },
    TEMPERATURE_ZONE: {
      FREEZE: {
        value: 'freeze',
        label: '低温',
      },
      CHILL: {
        value: 'chill',
        label: '中温',
      },
      KEEP: {
        value: 'keep',
        label: '保冷',
      },
      WARM: {
        value: 'warm',
        label: '加温',
      },
    },
    STANDBY_EQUIPMENT_TYPE: {
      YES: {
        value: 1,
        label: 'あり',
      },
      NO: {
        value: 0,
        label: 'なし',
      },
    },
    TOOLS_OTHER_TYPE: {
      ABS: {
        value: 'abs',
        label: 'ABS',
      },
      AIRBAG: {
        value: 'airbag',
        label: 'エアバッグ',
      },
      BACKMONITOR: {
        value: 'backmonitor',
        label: 'バックモニター',
      },
      CARNAVI: {
        value: 'carnavi',
        label: 'カーナビ',
      },
      CATACON: {
        value: 'catacon',
        label: 'キャタコン',
      },
      RADIO: {
        value: 'radio',
        label: '無線',
      },
      TOOLBOX: {
        value: 'toolbox',
        label: '工具',
      },
      WARNING_TRIANGLE: {
        value: 'warningTriangle',
        label: '三角反射板',
      },
    },
    PURCHASE_TYPE: {
      YES: {
        value: 1,
        label: 'あり',
      },
      NO: {
        value: 0,
        label: 'なし',
      },
    },
    LEACE_TYPE: {
      MAINTENANCE: {
        value: 'maintenance',
        label: 'メンテナンス付きリース',
      },
      BUY_OPTION: {
        value: 'buy-option',
        label: '購入選択権付きリース',
      },
      TIRE: {
        value: 'tire',
        label: 'タイヤリース',
      },
      OPEN_END: {
        value: 'open-end',
        label: 'オープンエンド',
      },
      CLOSE_END: {
        value: 'close-end',
        label: 'クローズエンド',
      },
    },
    CONTRACT_TYPE: {
      UNDER: {
        value: 'under',
        label: '契約中',
      },
      CANCELLATION: {
        value: 'cancellation',
        label: '解約済み',
      },
    },
    INSPETION_RESULTS: {
      YES: {
        value: 1,
        label: '良',
      },
      NO: {
        value: 0,
        label: '否',
      },
    },
    INSURANCE_STATUS: {
      YES: {
        value: 1,
        label: '契約中',
      },
      NO: {
        value: 0,
        label: '解約済み',
      },
    },
  },
  DRIVERS: {
    IS_DEPENDENT: {
      YES: {
        value: 1,
        label: 'あり',
      },
      NO: {
        value: 0,
        label: 'なし',
      },
    },
    CURRENT_CAREER_STATUS: {
      ENROLLED: {
        value: 'enrolled',
        label: '在籍',
      },
      REMOVED: {
        value: 'removed',
        label: '離籍済み',
      },
    },
    BASIC_LICENSE: {
      STANDARD: {
        value: 'standard',
        label: '普通',
      },
      SEMI_MIDSIZE: {
        value: 'semi_midsize',
        label: '準中型',
      },
    },
    EMPLOYMENT_STATUS: {
      EXECUTIVE: {
        value: 'executive',
        label: '役員',
      },
      REGULAR: {
        value: 'regular',
        label: '正社員',
      },
      PART_TIME: {
        value: 'part_time',
        label: 'パート・アルバイト',
      },
      TEMPORARY: {
        value: 'temporary',
        label: '派遣社員',
      },
    },
  },
  USER_EDUCATIONS: {
    IS_WEBTEST: {
      WEB: {
        value: 1,
        label: 'WEB',
      },
      PAPER: {
        value: 0,
        label: '筆記',
      },
    },
  },
  EDUCATION_SETTINGS: {
    IS_SHUFFLE: {
      TRUE: {
        value: 1,
        label: '有効',
      },
      FALSE: {
        value: 0,
        label: '無効',
      },
    },
    IS_AFTER_VIDEO: {
      TRUE: {
        value: 1,
        label: '有効',
      },
      FALSE: {
        value: 0,
        label: '無効',
      },
    },
    IS_COMMENTABLE: {
      TRUE: {
        value: 1,
        label: '有効',
      },
      FALSE: {
        value: 0,
        label: '無効',
      },
    },
    IS_DISABLED_FUTURE_ATTENDANCE: {
      TRUE: {
        value: 1,
        label: '有効',
      },
      FALSE: {
        value: 0,
        label: '無効',
      },
    },
  },
  BILLS: {
    HAS_TAX: {
      INCLUDE_TAX: {
        value: 1,
        label: '税込',
      },
      EXCLUDE_TAX: {
        value: 0,
        label: '税抜',
      },
    },
  },
  COMPANIES: {
    G_MARK: {
      YES: {
        value: 1,
        label: '取得済み',
      },
      NO: {
        value: 0,
        label: '未取得',
      },
    },
  },
  PARTNER_COMPANIES: {
    RELATION_CATEGORY: {
      OWNER: {
        value: 'owner',
        label: '荷主',
      },
      CARRIER: {
        value: 'carrier',
        label: '運送会社',
      },
      OTHERS: {
        value: 'others',
        label: 'その他',
      },
    },
    BILL_REFERENCE_DATE_TYPE: {
      PICK_UP_DATE: {
        value: 'pick_up_date',
        label: '積日',
      },
      DELIVERY_DATE: {
        value: 'delivery_date',
        label: '卸日',
      },
    },
    IS_INVOICE_SUPPORTED: {
      TRUE: {
        value: 1,
        label: '対応',
      },
      FALSE: {
        value: 0,
        label: '非対応',
      },
    },
    IS_CARGO_HANDLING: {
      YES: {
        value: 1,
        label: '可',
      },
      NO: {
        value: 0,
        label: '否',
      },
    },
    IS_BULK_CARGO: {
      YES: {
        value: 1,
        label: '可',
      },
      NO: {
        value: 0,
        label: '否',
      },
    },
    TEMPERATURE_ZONE: {
      DRY: {
        value: 'dry',
        label: 'ドライ',
      },
      REFRIGERATION: {
        value: 'refrigeration',
        label: '冷蔵',
      },
      FREEZING: {
        value: 'freezing',
        label: '冷凍',
      },
    },
    MODEL: {
      WING: {
        value: 'wing',
        label: 'ウィング',
      },
      BOX: {
        value: 'box',
        label: '箱',
      },
      HOOD: {
        value: 'hood',
        label: '幌',
      },
      FLAT: {
        value: 'flat',
        label: '平',
      },
      UNIC: {
        value: 'unic',
        label: 'ユニック',
      },
    },
    SIZE_DETAIL: {
      LOW_FLOOR: {
        value: 'low_floor',
        label: '低床',
      },
      MEDIUM_FLOOR: {
        value: 'medium_floor',
        label: '中低床',
      },
      HIGH_FLOOR: {
        value: 'high_floor',
        label: '高床',
      },
      FULL_WIDE: {
        value: 'full_wide',
        label: 'フルワイド',
      },
      SEMI_WIDE: {
        value: 'semi_wide',
        label: 'セミワイド',
      },
      STANDARD: {
        value: 'standard',
        label: '標準',
      },
      SHORT: {
        value: 'short',
        label: 'ショート',
      },
      LONG: {
        value: 'long',
        label: 'ロング',
      },
    },
  },
  ARTICLES: {
    IS_DRAFT: {
      NO: {
        value: 0,
        label: '公開',
      },
      YES: {
        value: 1,
        label: '非公開',
      },
    },
  },
  BILL_SETTING: {
    IS_SORT_DESC: {
      NO: {
        value: 0,
        label: '昇順',
      },
      YES: {
        value: 1,
        label: '降順',
      },
    },
  },
  PAYMENT_STATEMENT_SETTING: {
    IS_SORT_DESC: {
      NO: {
        value: 0,
        label: '昇順',
      },
      YES: {
        value: 1,
        label: '降順',
      },
    },
  },
  TIMECARDS: {
    CATEGORY: {
      WORK: {
        value: 'work',
        label: '勤務',
      },
      OFF: {
        value: 'off',
        label: '休日・休暇',
      },
    },
    MONTHLY: {
      MONTH_BEFORE_LAST: {
        value: 2,
        label: '先々月',
      },
      LAST_MONTH: {
        value: 1,
        label: '先月',
      },
      THIS_MONTH: {
        value: 0,
        label: '今月',
      },
    },
    DAILY: {
      DAY_BEFORE_LAST: {
        value: 2,
        label: '一昨日',
      },
      LAST_DAY: {
        value: 1,
        label: '昨日',
      },
      TODAY: {
        value: 0,
        label: '今日',
      },
    },
    TYPE: {
      NO: {
        value: 'office',
        label: '事業所',
      },
      YES: {
        value: 'driver',
        label: '従業員',
      },
    },
    REQUEST_STATUS: {
      PENDING: { value: 'pending', label: '承認待ち' },
      APPROVED: { value: 'approved', label: '承認済み' },
      REJECTED: { value: 'rejected', label: '差戻し' },
    },
    APPROVE_STATUS: {
      UNAPPLIED: { value: 'unapplied', label: '未承認' },
      // TODO: 従業員が申請できるようになった際はコメントアウトを外して、↑を削除
      // UNAPPLIED: { value: 'unapplied', label: '申請前' },
      // UNAPPROVED: { value: 'unapproved', label: '未承認' },
      APPROVED: { value: 'approved', label: '承認済み' },
    },
    ALERT_TYPE: {
      DAILY_HOUR_RESTRAINT: {
        value: 'daily_hour_restraint',
        label: '一日の拘束時間',
      },
      MONTHLY_HOUR_RESTRAINT: {
        value: 'monthly_hour_restraint',
        label: '月の拘束時間',
      },
      DAILY_HOUR_REST: {
        value: 'daily_hour_rest',
        label: '一日の休息期間',
      },
      MONTHLY_HOUR_OVERTIME: {
        value: 'monthly_hour_overtime',
        label: '月の残業時間',
      },
    },
  },
  DELIVERY_REQUEST_SETTING: {
    IS_SHOW_OWNER: {
      YES: {
        value: 1,
        label: '表示する',
      },
      NO: {
        value: 0,
        label: '表示しない',
      },
    },
  },
}
