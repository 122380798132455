// 会社管理は含めない
export const FEATURES = [
  { label: '教育管理', value: 'educations' },
  { label: '事故管理', value: 'accidents' },
  { label: '運転者台帳', value: 'drivers' },
  { label: '車両台帳', value: 'vehicles' },
  { label: 'アルコールチェック', value: 'alcoholChecks' },
  { label: '請求書管理', value: 'bills' },
  { label: '支払明細管理', value: 'paymentStatements' },
  { label: '売上管理', value: 'sales' },
  { label: '取引先管理', value: 'partnerCompanies' },
  { label: '案件管理', value: 'deliveryRequests' },
  { label: 'オンライン打診', value: 'proposalDeliveries' },
  { label: 'ファイル共有', value: 'resources' },
  { label: '車両定期点検計画', value: 'vehicleInspectionPlans' },
  { label: 'CSV設定', value: 'csvSettings' },
  { label: '給油履歴', value: 'refuelings' },
  { label: '燃費実績', value: 'fuelConsumptions' },
  { label: '車両コスト管理', value: 'vehicleCostManagements' },
  { label: '労務管理', value: 'timecard' },
  { label: '配車表', value: 'vehicleAllocationTable' },
]
