import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import AdminEducationAnnualPlansForm from 'components/organisms/admin/education_annual_plans/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'

function AdminEducationAnnualPlansDuplicate() {
  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.ANNUAL_PLANS.EDIT ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <h1>{ADMIN_MENUS.educations.children.education_annual_plans.title}</h1>
      <AdminEducationAnnualPlansForm type="duplicate" />
    </PageWrapper>
  )
}

export default AdminEducationAnnualPlansDuplicate
