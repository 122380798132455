import * as React from 'react'
import { Box } from '@mui/material'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  ADMIN_ROUTES,
  APPLICATION_NAME,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { AddButton } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useParams } from 'react-router-dom'
import EducationAttendancesTable from 'components/organisms/education_attendances/Table'
import EducationAttendanceListModal from 'components/organisms/education_attendances/AttendanceListModal'
import EducationNonattendanceListModal from 'components/organisms/education_attendances/NonattendanceListModal'
import { styled } from '@mui/system'
import { UserContext } from 'providers/UserProvider'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import useEducationAttendances from './hooks/useEducationAttendances'
import AttachedFilesModal from './components/AttachedFilesModal'
import { TextRed } from 'components/atoms/TextComponents'

function AdminEducationAttendancesChapterIndex() {
  const user = React.useContext(UserContext)
  const { id } = useParams()
  const params = {
    educationId: id,
    isWithinAYear: true,
  }
  const defaultSearchParams = {
    attendedYear: user.company.thisYear || new Date().getFullYear(),
    officeId: user.officeId,
  }
  const [searchParams, setSearchParams] =
    React.useState<SearchUserEducation>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const newLink = `${ADMIN_ROUTES.EDUCATION_ATTENDANCES_INDEX}${id}/new`
  const [showAttendanceListModal, setShowAttendanceListModal] =
    React.useState(false)
  const [showNonattendanceListModal, setShowNonattendanceListModal] =
    React.useState(false)
  const [showAttachedFilesModal, setShowAttachedFilesModal] =
    React.useState(false)
  const queryParams = useLocation().search
  const focusId = new URLSearchParams(queryParams).get('focus_id')

  const InvisibleForMobile = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  })

  const { educationAttendanceList, isLoadingEducationAttendancesData } =
    useEducationAttendances(params, searchParams, paginateParams)
  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.ATTENDANCES.INDEX ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <h1>{MENUS.educations.children.education_attendances.title}</h1>
        {user.admin && (
          <FlexBox alignItems="center">
            <InvisibleForMobile>
              <ApplyButton onClick={() => setShowNonattendanceListModal(true)}>
                未受講者一覧出力
              </ApplyButton>
              <Box ml={2}>
                <ApplyButton onClick={() => setShowAttendanceListModal(true)}>
                  受講履歴出力
                </ApplyButton>
              </Box>
              {/* TODO カスタム項目以外は添付ファイルボタンを隠す */}
              <Box ml={2}>
                <ApplyButton onClick={() => setShowAttachedFilesModal(true)}>
                  添付ファイル
                </ApplyButton>
              </Box>
            </InvisibleForMobile>
            <Box ml={2}>
              <AddButton href={newLink}>受講履歴の新規作成</AddButton>
            </Box>
          </FlexBox>
        )}
      </Box>
      <div>
        {!educationAttendanceList?.hasAttendanceDate && (
          <TextRed>
            ※年間計画の実施予定日が登録されていません。
            {user.admin ? (
              <>
                <a href="/admin/educations/annual_plans/">年間計画</a>
                から登録してください。
              </>
            ) : (
              <>管理者へお問合せください。</>
            )}
          </TextRed>
        )}
      </div>
      <h3>{educationAttendanceList?.title}</h3>
      <EducationAttendancesTable
        educationId={id}
        data={educationAttendanceList?.educationAttendances}
        totalCount={educationAttendanceList?.totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        searchParams={searchParams}
        defaultSearchParams={defaultSearchParams}
        withinTermCount={educationAttendanceList?.withinTermCount}
        outOfTermCount={educationAttendanceList?.outOfTermCount}
        hasAttendenceDate={educationAttendanceList?.hasAttendanceDate}
        focusId={parseInt(focusId)}
        isLoading={isLoadingEducationAttendancesData}
      />
      <EducationAttendanceListModal
        educationId={id}
        showModal={showAttendanceListModal}
        setShowModal={setShowAttendanceListModal}
      />
      <EducationNonattendanceListModal
        educationId={id}
        showModal={showNonattendanceListModal}
        setShowModal={setShowNonattendanceListModal}
      />
      <AttachedFilesModal
        educationId={id}
        showModal={showAttachedFilesModal}
        setShowModal={setShowAttachedFilesModal}
      />
    </PageWrapper>
  )
}

export default AdminEducationAttendancesChapterIndex
